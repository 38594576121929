import React from "react"
import { Link } from "gatsby"

const ReferenceTeaser = (props) => {

    const { post } = props;
    const { frontmatter: { tags } } = post

    /* eslint-disable-next-line */
    const Tags = tags.map(tag => <li className="tag" key={tag.toLowerCase().replace(/ /g, '_')}><Link to={`/tags/${tag}`} className="tag__link">{tag}</Link></li>);

    return (
        <article className="postTeaser">
            <header className="postTeaser__header">
                <h1 className="postTeaser__heading">
                    <Link className="postTeaser__link" to={post.fields.slug}>
                        {post.frontmatter.title}
                    </Link>
                </h1>
                {/* Tags && <ul>{Tags}</ul> */}
                <p className="postTeaser__date">First published: {post.frontmatter.datePublished}</p>
            </header>

            <Link className="postTeaser__link" to={post.fields.slug}>
                <p className="postTeaser__excerpt">{post.excerpt}</p>
            </Link>

            <footer className="postTeaser__footer">
                <Link className="cta" to={post.fields.slug}>
                    Read more
                </Link>
            </footer>
        </article>
    );

};

export default ReferenceTeaser