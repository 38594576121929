import React from 'react'
import { Link } from "gatsby"

function Breadcrumbs(props) {

    const { title, path } = props

    const labels = {
        HOME: 'Home',
        POSTS: 'Posts',
        PREFIX: 'You are here:',
        REFERENCES: 'References',
        CASE_STUDIES: 'Case studies',
    }

    const pathDepth = path.match(/\//g).length;

    let SecondLevel = <></>;
    let LastLevel = <></>;
    let FirstLevel = <Link to="/">{labels.HOME}</Link>;

    // second levels
    if (path.match('posts') !== null) {
        SecondLevel = <> | <span>{labels.POSTS}</span></>;
    }
    if (path.match('references') !== null) {
        SecondLevel = <> | <span>{labels.REFERENCES}</span></>;
    }
    if (path.match('case-studies') !== null) {
        SecondLevel = <> | <span>{labels.CASE_STUDIES}</span></>;
    }

    // Same level as home but should be shown as a virtual second level
    if (pathDepth === 1) {
        // case: Home
        if (path !== '/') {
            SecondLevel = <> | <span>{title}</span></>;
        } else {
            FirstLevel = <span>{labels.HOME}</span>;
        }
    }

    // second level settings if details in third level
    if (pathDepth === 3) {
        if (props.path.match('posts') !== null) {
            SecondLevel = <>| <Link to="/posts">{labels.POSTS}</Link></>;
        }
        if (props.path.match('references') !== null) {
            SecondLevel = <>| <Link to="/references">{labels.REFERENCES}</Link></>;
        }
        if (props.path.match('case-studies') !== null) {
            SecondLevel = <>| <Link to="/case-studies">{labels.CASE_STUDIES}</Link></>;
        }

        LastLevel = <>| <span>{title}</span></>
    }

    return (
        <nav className="breadcrumbs">
            <div className="breadcrumbs__content">
                {labels.PREFIX} {FirstLevel} {SecondLevel} {LastLevel}
            </div>
        </nav>
    )
}

export default Breadcrumbs