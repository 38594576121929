import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"

import List from "../layouts/list"
import Main from "../components/main"
import PageHeader from "../components/chrome/pageHeader"
import PageFooter from "../components/chrome/pageFooter"
import Referencelist from "../components/reference-list"
import Container from "../layouts/container"
import Breadcrumbs from "../components/chrome/breadcrumbs"

// eslint-disable-next-line no-unused-vars
import Styles from '../styles/styles.scss'

const PageReferences = () => {
    const query = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: {fileAbsolutePath: {regex: "/(content/case-studies/)/"}}
        sort: {fields: [frontmatter___date], order: DESC}
      ) {
        totalCount
        edges {
          node {
            id
            frontmatter {
              title
              subtitle
              date
              dateModified
              datePublished
              tags
            }
            fields {
              slug
            }
            excerpt
          }
        }
      }
    }
  `)

    return (
        <List>
            <SEO
                title="Case studies"
                description="Case studies and experiments I'm working on in my spare time."
            />
            <PageHeader />

            <Main>
                <Container>
                    <Breadcrumbs title="Case studies" path="/case-studies" />
                </Container>

                <div className="row">
                    <div className="col--16">
                        <Container>
                            <h1 className="h1">Case studies</h1>
                            <Referencelist data={query} />
                        </Container>
                    </div>
                </div>
            </Main>

            <PageFooter />
        </List>
    )
}

export default PageReferences