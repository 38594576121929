import React from "react"

import Referenceteaser from '../components/reference-teaser';

const ReferenceList = (props) => {

    const { data } = props
    const pages = data.allMarkdownRemark.edges;

    return (
        <aside className="referenceList">
            {/*<h1 className="referenceList__heading">References ({data.allMarkdownRemark.totalCount > 1 ? `${data.allMarkdownRemark.totalCount} references` : `${data.allMarkdownRemark.totalCount} reference`})</h1>*/}
            <ol className="referenceList__listing">
                {pages && pages.length > 0
                    ? pages.map(({ node }) => (
                        <li className="referenceList__item" key={node.id}>
                            <Referenceteaser post={node} />
                        </li>
                    ))
                    : <p>Currently there are no entries in this category.</p>
                }
            </ol>
        </aside>
    )
}

export default ReferenceList